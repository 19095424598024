body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #051923;
  --secondary-color: #003554;
  --tertiary-color: #006494;
  --quaternary-color: #0582CA;
  --quinary-color: #00A6FB;
  --senary-color: #b0dde7;
}

#root {
  max-width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.header {
  width: 100vw;
  display: grid;
  background-color: #051923;
  align-items: center;
  justify-items: center;
  padding: 1.5em;
}
.header h1 {
  font-size: 2.5rem;
  margin: .6em 0;
}
.header p {
  color: white;
  font-size: 1.15rem;
}

h1 {
  color: var(--senary-color);
}
h2 {
  color: #b0dde7;
}
h3 {
  color: rgb(255, 255, 255);
}

p {
  color: rgb(255, 255, 255);
}