.uploadSection {
    height: 100%;
    display: block;
    padding: 1em;
    padding-left: 1.75em;
    background-color: #161616;
    border-right: 5px solid var(--secondary-color);
}

.text-muted {
    color: rgb(187, 187, 187) !important;
}

.form-label {
    color: rgb(45, 178, 255);
}

.paperCollection {
    max-width: 100%;
    height: 100%;
    margin: 0;
    background-color: #f1fdff;
}

.paperCollection .header {
    width: auto;
    background-color: #292929;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collectionContainer {
    padding: 2em;
    place-content: center;
}

.uploadSection h3, .paperCollection h2 {
    margin-top: 0;
}

.paper-item {
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 1em;
}

.paper-item .card {
    border-radius: .5em;
    overflow: hidden;
}

.paper-item .card-header {
    display: grid;
    justify-items: center;
    background-color:#2477a0;
}

.paper-item .card-body {
    background-color: #161616;
    color: white;
    display: grid;
    max-width: 100%;
    word-break: break-all;
    align-items: end;
}